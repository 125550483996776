import classnames from 'classnames';
import Image from 'next/image';
import PropTypes from 'prop-types';

const buttonStyles = {
    primary: 'primary',
    ghost: 'ghost',
    borderLess: 'borderLess'
};

const sizes = {
    extraSmall: 'extraSmall',
    small: 'small',
    medium: 'medium',
    large: 'large',
    iconExtraSmall: 'iconExtraSmall',
    iconSmall: 'iconSmall',
    iconMedium: 'iconMedium',
    iconLarge: 'iconLarge'
};
const iconPositions = { left: 'left', right: 'right' };

const Button = ({
    icon = null,
    iconPosition = iconPositions,
    label = '',
    onClick = () => {},
    type = 'button',
    style = buttonStyles.primary,
    fullWidth = false,
    className = '',
    disabled = false,
    size = sizes.medium,
    btnClass = ''
}) => (
    <button
        type={type}
        onClick={onClick}
        className={classnames(
            [className],
            'text-white rounded font-semibold disabled:bg-primary-100 disabled:text-primary-300 disabled:outline-none transform transition-transform active:scale-90 duration-300',
            `${btnClass}`,
            {
                'bg-primary-900 lg:hover:bg-primary-800 focus:bg-primary-900 active:bg-primary-900 lg:active:hover:bg-primary-800 lg:focus:hover:bg-primary-800 lg:visited:bg-primary-800':
                    style === buttonStyles.primary,
                'text-primary-900 outline outline-1 outline-primary-800 lg:hover:bg-primary-50 focus:bg-primary-50 focus:outline-2 active:bg-primary-200 visited:bg-primary-200':
                    style === buttonStyles.ghost,
                'text-primary-900 lg:hover:text-primary-800 focus:text-primary-800 lg:hover:bg-primary-100 focus:bg-primary-100 focus:outline focus:outline-primary-800 focus:outline-2 active:bg-primary-200 visited:bg-primary-200':
                    style === buttonStyles.borderLess,
                'py-4 px-8 text-xl': size === sizes.large,
                'py-3.5 px-8 text-base': size === sizes.medium,
                'py-3 px-6 text-sm': size === sizes.small,
                'py-2 px-6 text-sm': size === sizes.extraSmall,
                'p-4': size === sizes.iconLarge,
                'p-3': size === sizes.iconMedium,
                'px-2 py-2': size === sizes.iconSmall,
                'p-2': size === sizes.iconExtraSmall,
                'w-full': fullWidth,
                'flex items-center flex-row-reverse space-x-reverse space-x-2':
                    iconPosition == iconPositions.left,
                'flex items-center flex-row space-x-2':
                    iconPosition == iconPositions.right,
                disabled
            }
        )}
        disabled={disabled}
    >
        {label && (
            <span className={icon && classnames([className])}>{label}</span>
        )}

        {icon && (
            <Image
                src={icon}
                width={10}
                height={10}
                alt="icon"
                className={classnames([className], {
                    'large w-6': size === sizes.large,
                    'iconLarge w-6': size === sizes.iconLarge,
                    'medium w-6': size === sizes.medium,
                    'iconMedium w-6': size === sizes.iconMedium,
                    'small w-4': size === sizes.small,
                    'iconSmall w-6': size === sizes.iconSmall,
                    'extraSmall w-4': size === sizes.extraSmall,
                    'iconExtraSmall w-4': size === sizes.iconExtraSmall
                })}
            />
        )}
    </button>
);

Button.propTypes = {
    style: PropTypes.oneOf(Object.values(buttonStyles)),
    size: PropTypes.oneOf(Object.values(sizes)),
    iconPosition: PropTypes.oneOf(Object.values(iconPositions)),
    label: PropTypes.string,
    disabled: PropTypes.bool,
    icon: PropTypes.string,
    onClick: PropTypes.func,
    type: PropTypes.string,
    fullWidth: PropTypes.bool,
    className: PropTypes.string,
    btnClass: PropTypes.string
};

export default Button;

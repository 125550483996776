import PropTypes from 'prop-types';
import { useEffect } from 'react';
import Avatar from '../../atoms/Avatar';
import Button from '../../atoms/Button';
import IcomoonIcon from '../../atoms/IcomoonIcon';
import Logo from '../../atoms/Logo';
import Text from '../../atoms/Text';

const FullScreenNav = ({
    logo,
    logoAlt,
    logoWidth,
    logoHeight,
    buttonHandler,
    username,
    handleCloseFulNav,
    fullNav,
    ...property
}) => {
    useEffect(() => {
        if (fullNav) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'visible';
        }
    }, [fullNav]);
    return (
        <div
            className={`fixed top-0 z-50 h-screen flex bg-black bg-opacity-90 w-full ${property.className}`}
        >
            <div className="w-[70%] bg-white h-full">
                <Logo
                    src={logo}
                    alt={logoAlt}
                    width={logoWidth}
                    height={logoHeight}
                    className="px-4 py-2"
                />
                <div className="flex space-x-4 items-center px-4 py-3 bg-primary-900 text-secondary-800">
                    <Avatar size="medium" />
                    <Text
                        variant="bodySmall"
                        fontWeight="font-semibold"
                    >
                        Hi, {username}
                    </Text>
                </div>
                <div className="px-4 py-6">
                    <Button
                        label={'Sign Out'}
                        size="small"
                        style={'ghost'}
                        onClick={buttonHandler}
                    />
                </div>
            </div>
            <div className="px-3 py-5">
                <IcomoonIcon
                    icon={'close'}
                    size="15"
                    className="cursor-pointer"
                    onClick={handleCloseFulNav}
                />
            </div>
        </div>
    );
};

export default FullScreenNav;

FullScreenNav.propTypes = {
    className: PropTypes.string
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
    if (process.env.NEXT_PUBLIC_GTAG === 'GTA-dummy') return;

    window.gtag('config', process.env.NEXT_PUBLIC_GTAG, {
        page_path: url
    });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }, custom) => {
    if (process.env.NEXT_PUBLIC_GTAG === 'GTA-dummy') return;

    window.gtag('event', action, {
        event_category: category,
        event_label: label,
        value,
        ...custom
    });
};

import PropTypes from 'prop-types';
import { useState } from 'react';
import Avatar from '../atoms/Avatar';
import Button from '../atoms/Button';
import IcomoonIcon from '../atoms/IcomoonIcon';
import Logo from '../atoms/Logo';
import MenuList from '../atoms/MenuList';
import Text from '../atoms/Text';
import FullScreenNav from '../components/common/FullScreenNav';

const Navbar = ({
    className = '',
    shadow = 'shadow-nav',
    cardPadding = 'px-8 py-5',
    logo = '/images/logo.svg',
    logoAlt = 'MVP Rockets',
    logoWidth = '140',
    logoHeight = '40',
    isMenu,
    menuItem = [],
    buttonLabel = 'Logout',
    buttonStyle = 'ghost',
    extraFeaturs,
    navItemsSpace,
    username,
    buttonHandler
}) => {
    const [fullNav, setfullNav] = useState(false);
    const handleFulNav = () => {
        setfullNav((prevState) => true);
    };

    const handleCloseFulNav = () => {
        setfullNav((prevState) => false);
    };
    return (
        <>
            <FullScreenNav
                logo="/images/climateXcapital.png"
                logoAlt="logo"
                logoWidth="88"
                logoHeight="88"
                username={username}
                handleCloseFulNav={handleCloseFulNav}
                buttonHandler={buttonHandler}
                fullNav={fullNav}
                className={`md:hidden ${
                    fullNav
                        ? 'ease-in-out duration-100'
                        : 'ease-in-out duration-100 -translate-x-[100%]'
                } `}
            />
            <div
                className={`${shadow} ${cardPadding} ${className} flex flex-row justify-between items-center bg-white w-full`}
            >
                <Logo
                    src={logo}
                    alt={logoAlt}
                    width={logoWidth}
                    height={logoHeight}
                />

                <div className={`flex items-center ${navItemsSpace}`}>
                    {extraFeaturs ? (
                        <>
                            <div className="space-x-4 items-center hidden md:flex">
                                <Avatar size="medium" />
                                <Text
                                    variant="body"
                                    fontWeight="font-semibold"
                                    className="text-primary-900"
                                >
                                    Hi, {username}
                                </Text>
                            </div>
                            <div
                                className="block md:hidden cursor-pointer"
                                onClick={handleFulNav}
                            >
                                <IcomoonIcon
                                    icon={'hamburger'}
                                    size="24"
                                    color={'black'}
                                />
                            </div>
                        </>
                    ) : (
                        ''
                    )}

                    {isMenu ? <MenuList menuItem={menuItem} /> : ''}

                    <Button
                        label={buttonLabel}
                        size="small"
                        style={buttonStyle}
                        onClick={buttonHandler}
                        className="hidden md:block"
                    />
                </div>
            </div>
        </>
    );
};

export default Navbar;

Navbar.propTypes = {
    shadow: PropTypes.string,
    logo: PropTypes.string,
    logoAlt: PropTypes.string,
    username: PropTypes.string,
    navItemsSpace: PropTypes.string,
    menuItem: PropTypes.array
};

import PropTypes from 'prop-types';

const Text = ({
    variant = 'body',
    fontSize = '',
    fontWeight = '',
    children,
    className,
    onClick
}) => {
    const TextType = {
        body: 'body',
        bodySmall: 'bodySmall',
        caption: 'caption'
    };

    const TextStyle = {
        body: 'text-base',
        bodySmall: 'text-sm',
        caption: 'text-xs'
    };
    return (
        <p
            variant={TextType[variant]}
            className={`${TextStyle[variant]} ${fontSize} ${fontWeight} ${className}`}
            onClick={onClick}
        >
            {children}
        </p>
    );
};

export default Text;

Text.propTypes = {
    variant: PropTypes.string,
    children: PropTypes.node,
    fontSize: PropTypes.string,
    fontWeight: PropTypes.string,
    className: PropTypes.string
};

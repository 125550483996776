const updateLastSeenDate = async (
    id,
    lastSeenDate = new Date().toLocaleString('en-IN')
) =>
    fetch('/api/user', {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            id,
            lastSeenDate
        })
    }).then((data) => {
        const resp = data.json();
        return resp;
    });
export default updateLastSeenDate;
